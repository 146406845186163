import React, { useState, useEffect } from 'react';
import { ConnectButton, useCurrentAccount } from '@mysten/dapp-kit';
import '@mysten/dapp-kit/dist/index.css';
import './App.css';
// Import icons
import SUIIcon from './icons/SUI.png';
import OXSUIIcon from './icons/OXSUI.png';
import SUITABLEIcon from './icons/SUITABLE.png';
import treasuryIcon from './icons/icon.png'; // Assuming the treasury icon is named icon.png
import appBg from './videos/appbg.mp4';
function App() {
  const account = useCurrentAccount();
  const [balances, setBalances] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [walletBalances, setWalletBalances] = useState([]);
  const [isWalletLoading, setIsWalletLoading] = useState(false);

  const rpc_url = 'https://fullnode.mainnet.sui.io:443';
  const owner_address = "0x0a76b44b6c38fb438c91b761bae1b4306b1f5601e8af67cce9d80845e83627b7";
  const listedCoins = ['SUI', 'OXSUI', 'SUITABLE']; // Example array, adjust as necessary

  // Mapping from coin names to icon imports
  const iconMap = {
    SUI: SUIIcon,
    OXSUI: OXSUIIcon,
    SUITABLE: SUITABLEIcon,
  };

  const getWalletBalances = async () => {
    if (!account) return; // Exit if no account is connected

    setIsWalletLoading(true);
    try {
      const payload = {
        "jsonrpc": "2.0",
        "id": 1,
        "method": "suix_getAllBalances",
        "params": [account.address] // Use connected account's address
      };

      const response = await fetch(rpc_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const { result } = await response.json();
        const filteredBalances = result
          .filter(({ coinType }) => listedCoins.includes(coinType.split('::').pop()))
          .map(({ coinType, totalBalance }) => ({
            name: coinType.split('::').pop(),
            balance: Math.round(Number(totalBalance) / Math.pow(10, 9)),
            icon: iconMap[coinType.split('::').pop()], // Use iconMap here
          }));
        setWalletBalances(filteredBalances);
      } else {
        console.error(`Error: ${response.status}, ${await response.text()}`);
      }
    } catch (error) {
      console.error('Error fetching wallet balances:', error);
    } finally {
      setIsWalletLoading(false);
    }
  };

  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

const handleRefresh = () => {
    getAllBalances(); // Refresh global balances
    getWalletBalances(); // Refresh connected wallet balances

    setIsRefreshDisabled(true);
    setTimeout(() => {
        setIsRefreshDisabled(false);
    }, 10000); // Re-enable after 10 seconds
};

  const getAllBalances = async () => {
    setIsLoading(true);
    try {
      const payload = {
        "jsonrpc": "2.0",
        "id": 1,
        "method": "suix_getAllBalances",
        "params": [owner_address]
      };

      const response = await fetch(rpc_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const { result } = await response.json();
        const filteredBalances = result
      .filter(({ coinType }) => listedCoins.includes(coinType.split('::').pop()))
      .map(({ coinType, totalBalance }) => ({
        name: coinType.split('::').pop(),
        balance: Math.round(Number(totalBalance) / Math.pow(10, 9)),
        icon: iconMap[coinType.split('::').pop()], // Use iconMap here
      }));
        setBalances(filteredBalances);
      } else {
        console.error(`Error: ${response.status}, ${await response.text()}`);
      }
    } catch (error) {
      console.error('Error fetching balances:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBalances();
    getWalletBalances(); // Fetch balances for the connected wallet
}, [account]); // Re-run when account changes

  return (
    <div className="App">
              <div id="bgVideoWrapper"><video  src={appBg} autoPlay muted playsInline loop id="bgVideo">
</video></div>
      <header className="App-header">
        <h1>TABLAPP</h1>
        <ConnectButton />
        {account && <p id="connected-address">Connected as: {account.address}</p>}
        </header>

        <div id="app-wrapper">
        <div class="app-container balances-container" id="balances-container">
          <div id="treasury-heading"><img id="treasury-icon" src={treasuryIcon} alt="Table Treasury Icon" /><h2> Table Treasury</h2></div>
          {isLoading ? <p>Loading balances...</p> : (
            <table>
              <tbody>
                {balances.map(({ name, balance, icon }) => (
                  <tr key={name}>
                    <td><img src={icon} alt={`${name} icon`} style={{ width: '32px' }} /></td>
                    <td>{name}</td>
                    <td>{balance.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <button class="button-30" onClick={getAllBalances} style={{ marginTop: '20px' }}>Refresh Balances</button>
        </div>
        <div class="app-container" id="NFT-container"><h2> NFT Treasury</h2><h4>Coming Soon...</h4></div>
        
        </div>
        <div id="app-column-2">
        <div class="app-container" id="account-container">
    <h2>Your Account</h2>
    {isWalletLoading ? <p>Loading balances...</p> : (
        <table>
            <tbody>
                {walletBalances.map(({ name, balance, icon }) => (
                    <tr key={name}>
                        <td><img src={icon} alt={`${name} icon`} style={{ width: '32px' }} /></td>
                        <td>{name}</td>
                        <td>{balance.toLocaleString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )}
    <button class="button-30" onClick={handleRefresh} disabled={isRefreshDisabled} style={{ marginTop: '20px' }}>
        Refresh Balances
    </button>
</div>
          </div>
      
    </div>
  );
}

export default App;